import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    Application,
    ApplicationNote,
    ApplicationExam,
    ApplicationAuxData,
    ApplicationPerson,
    ApplicationPersonDebt,
    ApplicationContractDebt,
    ApplicationEducation,
    ApplicationStatus,
    ApplicationSearch,
    ApplicationContractStatus,
    ApplicationProgramPriority,
    ApplicationProgram,
    ApplicationPersonDocument,
    ApplicationPreviousUniversity,
    ApplicationSchedule,
    ApplicationPaymentSchedule,
    ApplicationPaymentResponse,
    ApplicationUnitedRZStatus,
    StudentPayStatusModel,
    ViisGrade,
    PersonalEmailToApplicantModel,
    GradeSystemType,
    IApplicationBalanceInfo
} from '../models/Application';
import { StudentPayModel } from '../models/StudentPayModel';

import { AuthService } from './auth.service';

import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
* Application service
*/
@Injectable()
export class ApplicationService {
    constructor(
        private http: HttpClient,
        public auth: AuthService) { }

    public get apiUrl(): string { return `${ENV.apiUrl}/applications` }
    public get apiPayUrl(): string { return `${ENV.apiUrl}/studentpay` }

    /**
     * Ensure application program payment schedules exist.
     * @param applicationId
     * @param programId
     */
    ensurePaymentSchedules(applicationId: number, programId: number): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/${programId}/ensurePaymentSchedules`;
        return this.http.post(url, null);
    }

    getPayments(programId: number, applicationid: number): Observable<ApplicationSchedule[]> {
        const url = `${this.apiUrl}/${applicationid}/payments?programId=${programId}`;
        return this.http.get<ApplicationSchedule[]>(url);
    }

    getPaymentSchedule(programId: number, applicationid: number): Observable<ApplicationPaymentSchedule[]> {
        const url = `${this.apiUrl}/${applicationid}/paymentschedule?programId=${programId}`;
        return this.http.get<ApplicationPaymentSchedule[]>(url);
    }

    getSpecialCondition(args: {
        programId: number,
        applicationid: number,
        protocolDate: Date,
        protocolNr: string,
        totalCP: number,
        schedulePaymentStartDate: Date,
        schedulePaymentEndDate: Date,
        medInstitutionId: string,
        discountId: string,
        templateText: string,
        startYear?: number,
        startSemester?: number
    }): Observable<string> {
        const url = `${this.apiUrl}/${args.applicationid}/specialcondition`;
        return this.http.post<string>(url, {
            ProgramId: args.programId,
            TemplateText: args.templateText,
            ProtocolDate: args.protocolDate,
            ProtocolNr: args.protocolNr,
            TotalCP: args.totalCP,
            SchedulePaymentStartDate: args.schedulePaymentStartDate,
            SchedulePaymentEndDate: args.schedulePaymentEndDate,
            MedInstitutionId: args.medInstitutionId,
            DiscountId: args.discountId,
            StartYear: args.startYear,
            StartSemester: args.startSemester
        });
    }

    getPaymentSemesterList(programId: number, applicationid: number): Observable<ApplicationPaymentSchedule[]> {
        const url = `${this.apiUrl}/${applicationid}/paymentlist?programId=${programId}`;
        return this.http.get<ApplicationPaymentSchedule[]>(url);
    }

    setCreditPoints(programId: number, applicationid: number, payment: ApplicationPaymentSchedule, minPaymentDate?: Date): Observable<ApplicationPaymentResponse> {
        const url = `${this.apiUrl}/${applicationid}/program/${programId}/updatecp`;
        payment.MinPaymentDate = minPaymentDate;
        return this.http.put<ApplicationPaymentResponse>(url, payment);
    }

    createPaymentSchedules(applicationId: number, programId: number, semester?: number, discountId?: string): Observable<ApplicationPaymentResponse> {
        const url = `${this.apiUrl}/${applicationId}/program/${programId}/createPaymentSchedules`;
        return this.http.post<ApplicationPaymentResponse>(url, {
            Semester: semester,
            DiscountId: discountId
        });
    }

    getMy(): Observable<any[]> {
        const url = `${this.apiUrl}/my`;
        return this.http.get<any[]>(url);
    }

    getById(id: number): Observable<Application> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<Application>(url).pipe(map(data => {
            if (data.PhotoTimestamp) data.PhotoTimestamp = Date.parse(data.PhotoTimestamp.toString());
            return data;
        }));
    }

    getByNumber(appNumber: string): Observable<Application> {
        const url = `${this.apiUrl}/getByNumber(${appNumber})`;
        return this.http.get<Application>(url).pipe(map(data => {
            if (data.PhotoTimestamp) data.PhotoTimestamp = Date.parse(data.PhotoTimestamp.toString());
            return data;
        }));
    }

    getPreviousUniversity(applicationId: number): Observable<ApplicationPreviousUniversity> {
        const url = `${this.apiUrl}/${applicationId}/previousUniversity`;
        return this.http.get<ApplicationPreviousUniversity>(url);
    }

    savePreviousUniversity(item: ApplicationPreviousUniversity): Observable<any> {
        const url = `${this.apiUrl}/${item.ApplicationId}/previousUniversity`;
        return this.http.post(url, item);
    }

    create(admissionId: number, personCode?: string, email?: string, force?: boolean, applicationNumber?: string) {
        const url = `${this.apiUrl}`;
        return this.http.post<{
            Id?: number,
            Number?: string,
            Conflicts: {
                Id: number,
                Number: string,
                Name: string,
                Surname: string,
                Email: string,
                PersonCode: string
            }[]
        }>(url, {
            AdmissionId: admissionId,
            PersonCode: personCode,
            Email: email,
            Force: force,
            ApplicationNumber: applicationNumber
        });
    }

    update(item: Application): Observable<Application> {
        const url = `${this.apiUrl}/${item.Id}`;
        return this.http.put<Application>(url, item);
    }

    delete(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url);
    }

    getPrograms(id: number): Observable<ApplicationProgram[]> {
        const url = `${this.apiUrl}/${id}/programs`;
        return this.http.get<ApplicationProgram[]>(url);
    }

    addProgram(applicationId: number, programId: number, specializationId: number = null, universityId: string = ''): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/programs`;
        const postBody = { ProgramId: programId, SpecializationId: specializationId, UniversityId: universityId };
        return this.http.post(url, postBody);
    }

    removeProgram(applicationId: number, programId: number): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/programs/${programId}`;
        return this.http.delete(url);
    }

    setProgramPriority(applicationId: number, programId: number, priority: number): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/programs/${programId}/priority`;
        return this.http.put(url, { Priority: priority });
    }

    updateProgramWorkplace(applicationId: number, programId: number, workplace: ApplicationProgram): Promise<boolean> {
        const url = `${this.apiUrl}/${applicationId}/programs/${programId}/workplace`;
        return this.http.put<boolean>(url, {
            HasWorkplace: workplace.HasWorkplace,
            WorkplaceId: workplace.WorkplaceId,
            Workplace: workplace.Workplace
        }).toPromise();
    }

    setProgramPriorities(applicationId: number, priorities: ApplicationProgramPriority[]): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/priorities`;
        return this.http.put(url, priorities);
    }

    getNotes(id: number, position?: string, option?: string): Observable<ApplicationNote[]> {
        const url = `${this.apiUrl}/${id}/notes`;
        let para: any = {};

        if (position)
            para.position = position;

        if (option)
            para.option = option;

        return this.http.get<ApplicationNote[]>(url, { params: para });
    }

    saveNote(note: ApplicationNote): Observable<ApplicationNote> {
        if (!note.Id || note.Id < 1) {
            const url = `${this.apiUrl}/${note.ApplicationId}/notes`;
            return this.http.post<ApplicationNote>(url, {
                Text: note.Text,
                Position: note.Position
            });
        } else {
            const url = `${this.apiUrl}/${note.ApplicationId}/notes/${note.Id}`;
            return this.http.put<ApplicationNote>(url, {
                Text: note.Text
            });
        }
    }

    deleteNote(applicationId: number, noteId: number): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/notes/${noteId}`;
        return this.http.delete(url);
    }

    getExams(id: number): Observable<ApplicationExam[]> {
        const url = `${this.apiUrl}/${id}/exams`;
        return this.http.get<ApplicationExam[]>(url);
    }

    refreshViisGrades(id: number) {
        const url = `${this.apiUrl}/${id}/refreshViis`;
        return this.http.get<{ appExamId: number, grades: ViisGrade[] }[]>(url);
    }

    refreshCrmGrades(id: number) {
        const url = `${this.apiUrl}/${id}/refreshCrmGrades`;
        return this.http.get<{ appExamId: number, grades: number[] }[]>(url);
    }

    saveExam(applicationId: number, applicationExamId: number, data: {
        grade: number,
        gradeSystem: GradeSystemType,
        rawGrade?: number,
        gradeCoef?: number,
        auxType?: string
    }): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/exams/${applicationExamId}`;
        return this.http.put(url, {
            ...data
        });
    }

    getPersonDocuments(id: number): Observable<ApplicationPersonDocument[]> {
        const url = `${this.apiUrl}/${id}/personDocuments`;
        return this.http.get<ApplicationPersonDocument[]>(url);
    }

    savePersonDocuments(id: number, data: ApplicationPersonDocument[]): Observable<any> {
        const url = `${this.apiUrl}/${id}/personDocuments`;
        return this.http.post(url, data);
    }

    getEducations(id: number): Observable<ApplicationEducation[]> {
        const url = `${this.apiUrl}/${id}/educations`;
        return this.http.get<ApplicationEducation[]>(url);
    }

    saveEducations(id: number, data: ApplicationEducation[]) {
        const url = `${this.apiUrl}/${id}/educations`;
        return this.http.post<{ [key: number]: number }>(url, data);
    }

    getAuxData(id: number): Observable<ApplicationAuxData[]> {
        const url = `${this.apiUrl}/${id}/auxData`;
        return this.http.get<ApplicationAuxData[]>(url);
    }

    saveAuxData(id: number, data: ApplicationAuxData[]): Observable<any> {
        const url = `${this.apiUrl}/${id}/auxData`;
        const formData = new FormData();

        data.sort((t1,t2) => (t1.Binary ? 0 : 1) - (t2.Binary ? 0 : 1));

        formData.append('data', JSON.stringify(data));

        data.forEach(t => {
            if (t.Binary)
                formData.append('file[]', t.Binary, t.Binary.name);
        });

        return this.http.post(url, formData);
    }

    updateSendExamToDvs(id: number, appExamId: number, value: boolean): Promise<boolean> {
        const url = `${this.apiUrl}/${id}/exams/${appExamId}/updatesendtodvs`;
        return this.http.post<boolean>(url, value, { headers: { "Content-Type": "application/json" }}).toPromise();
    }

    getAuxFileUrl(auxId: number): string {
        return `${this.apiUrl}/auxData/${auxId}/file?SessionKey=${this.auth.sessionKey}`;
    }

    getPhotoUrl(id: number, timestamp: number, size: string = 'small'): string {
        return `${this.apiUrl}/${id}/photo?size=${size}&SessionKey=${this.auth.sessionKey}&${timestamp}`;
    }

    savePhoto(id: number, file: File | Blob): Observable<any> {
        const url = `${this.apiUrl}/${id}/photo`;

        const formData = new FormData();
        formData.append('file', file);

        return this.http.post(url, formData);
    }

    deletePhoto(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/photo`;
        return this.http.delete(url);
    }

    getPdfUrl(id: number): string {
        return `${this.apiUrl}/${id}/pdf?SessionKey=${this.auth.sessionKey}`;
    }

    getPerson(id: number): Observable<ApplicationPerson[]> {
        const url = `${this.apiUrl}/${id}/person`;
        return this.http.post<ApplicationPerson[]>(url, null);
    }

    checkPerson(id: number): Observable<ApplicationPerson[]> {
        const url = `${this.apiUrl}/${id}/checkPerson`;
        return this.http.post<ApplicationPerson[]>(url, null);
    }

    checkBalance(ids: number[]): Observable<ApplicationPersonDebt[]> {
        const url = `${this.apiUrl}/getBalance`;
        return this.http.post<ApplicationPersonDebt[]>(url, { ids: ids });
    }

    checkContractBalance(applicationId: number): Observable<ApplicationContractDebt> {
        const url = `${this.apiUrl}/${applicationId}/getContractBalance`;
        return this.http.get<ApplicationContractDebt>(url);
    }

    linkPerson(applicationId: number, crmPersonId?: string): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/linkPerson?crmPersonId=${crmPersonId}`;
        return this.http.put(url, null);
    }

    unlinkPerson(applicationId: number): Observable<any> {
        const url = `${this.apiUrl}/${applicationId}/unlinkPerson`;
        return this.http.put(url, null);
    }

    setStatus(id: number, status: ApplicationStatus): Observable<any> {
        const url = `${this.apiUrl}/${id}/status`;
        return this.http.put(url, { status: status });
    }

    search(options?: any): Observable<ApplicationSearch[]> {
        const url = `${this.apiUrl}/search`;

        if (!options)
            options = {};

        return this.http.get<ApplicationSearch[]>(url, {
            params: options
        });
    }

    getContractStatus(id: number): Observable<ApplicationContractStatus> {
        const url = `${this.apiUrl}/${id}/contract`;
        return this.http.get<ApplicationContractStatus>(url);
    }

    setContractStatus(id: number, statusId: string, terminationReasonId: string = ''): Observable<any> {
        const url = `${this.apiUrl}/${id}/contract`;
        if (terminationReasonId)
            return this.http.put(url, { sentStatusId: statusId, reasonId: terminationReasonId });
        else
            return this.http.put(url, { sentStatusId: statusId });
    }

    validate(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/validate`;
        return this.http.get(url);
    }

    confirm(id: number, type: string, value: boolean): Observable<any> {
        const url = `${this.apiUrl}/${id}/confirm`;
        return this.http.put(url, {
            type: type,
            value: value
        });
    }

    setStudiesAtLaterStages(id: number, value: boolean): Observable<any> {
        const url = `${this.apiUrl}/${id}/studiesAtLaterStages(${value})`;
        return this.http.put(url, null);
    }

    setIsGraduate(id: number, value: boolean): Observable<any> {
        const url = `${this.apiUrl}/${id}/isGraduate(${value})`;
        return this.http.put(url, null);
    }

    setIsIncomplete(id: number, value: boolean): Observable<any> {
        const url = `${this.apiUrl}/${id}/isIncomplete(${value})`;
        return this.http.put(url, null);
    }

    setApplicationForCreditTransfer(id: number, value: boolean): Observable<any> {
        const url = `${this.apiUrl}/${id}/applicationForCreditTransfer(${value})`;
        return this.http.put(url, null);
    }

    setUniversity(id: number, programId: number, universityId: string): Observable<any> {
        const url = `${this.apiUrl}/${id}/setUniversity(${programId})`;
        return this.http.put(url, { UniversityId: universityId });
    }
    enroll(id: number, programId: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/enroll(${programId})`;
        return this.http.put(url, null);
    }

    generatePdf(id: number) {
        const url = `${this.apiUrl}/${id}/pdf`;
        return this.http.post<{ Id: number, FileName: string, Created: Date }>(url, null);
    }

    setUnitedRZStatus(id: number, status: ApplicationUnitedRZStatus): Observable<any> {
        const url = `${this.apiUrl}/${id}/unitedRZStatus`;
        return this.http.put(url, { status: status });
    }

    getPersonCrmPhoto(email: string): Observable<string> {
        const url = `${ENV.apiUrl}/crm/personPhoto`;
        return this.http.get<string>(url, { params: { email } });
    }

    getPersonPhotoFromApplication(name: string, surname: string, email: string, personCode: string, phone: string, appId: number, isSame: boolean): Observable<string> {
        const url = `${this.apiUrl}/personPhoto`;
        const params: any = {
            name,
            surname,
            email,
            personCode,
            phone,
            appId,
            isSame
        };
        return this.http.get<string>(url, { params });
    }
    
    pay(contract: StudentPayModel): Observable<any> {
        const url = `${this.apiPayUrl}/pay`;
        return this.http.post(url, contract);
    }

    getPayInfo(aplicationId: number): Observable<StudentPayStatusModel> {
        const url = `${this.apiPayUrl}/info` + '?aplicationId=' + aplicationId;
        return this.http.get<StudentPayStatusModel>(url);
    }

    setPayInfo(aplicationId: number, regPaid: boolean, firstPaid: boolean): Observable<any> {
        const url = `${this.apiPayUrl}/${aplicationId}/set`;
        return this.http.post(url, { RegistrationPaid: regPaid, FirstPaymentPaid: firstPaid });
    }

    removeLateSubmissionTag(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/removeLateSubmissionTag`;
        return this.http.put(url, null);
    }

    getHistory(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/history`;
        return this.http.get(url);
    }

    getEducationsHistory(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/educationsHistory`;
        return this.http.get(url);
    }

    getPersonDocumentsHistory(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/personDocumentsHistory`;
        return this.http.get(url);
    }

    getProgramsHistory(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/programsHistory`;
        return this.http.get(url);
    }

    getExamsHistory(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/examsHistory`;
        return this.http.get(url);
    }

    sign(id: number): Observable<any> {
        const url = `${this.apiUrl}/${id}/sign`;
        return this.http.post(url, null);
    }

    uploadEdoc(id: number, file: File, mutuallySigned?: boolean): Observable<any> {
        const url = `${this.apiUrl}/${id}/edoc`;

        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('mutuallySigned', mutuallySigned ? 'true' : 'false');

        return this.http.post(url, formData);
    }

    getApplicationAuxDataProgram(id: number): Observable<any> {
        const url = `${this.apiUrl}/auxDataProgram/${id}`;
        return this.http.get(url);
    }

    getBalance(id: number): Observable<IApplicationBalanceInfo[]> {
        const url = `${this.apiUrl}/${id}/balanceInfo`;
        return this.http.get<IApplicationBalanceInfo[]>(url);
    }

    /** Query LV address from a RSU service */
    async queryAddress(query: AddressApiQuery): Promise<AddressApiResponse> {
        const url = `${this.apiUrl}/queryAddress`;
        var result = await this.http.post<AddressApiResponse>(url, query).toPromise();
        for (let key in result) {
            if (key.endsWith("Options") && result[key]) {
                const options = result[key];
                const asMap = new Map<string, string>();
                for (let id in options) {
                    asMap.set(id, options[id]);
                }
                result[key] = asMap;
            }
        }
        return result;
    }

    async sendPersonalEmail(appNumber: string, args: { subject: string, body: string, applicationId: number, applicationNumber: string, address: string }) : Promise<PersonalEmailToApplicantModel> {
        const url = `${this.apiUrl}/sendPersonalApplicantEmail/${appNumber}`;
        const result = await this.http.post<PersonalEmailToApplicantModel>(url, args).toPromise();
        return result;
    }

    async getPersonalEmails(appId: number) : Promise<PersonalEmailToApplicantModel[]> {
        const url = `${this.apiUrl}/getPersonalApplicantEmails/${appId}`;
        let result = await this.http.get<PersonalEmailToApplicantModel[]>(url).toPromise();
        return result;
    }

    async getStartPageURL() :Promise<string> {
        const url = `${this.apiUrl}/getStartPageURL`;
        const result = await this.http.get<string>(url).toPromise();
        return result;
    }

}

export type AddressLookupOptions = Map<string, string>;

export class AddressApiQuery {
    CityId: string = '';
    ParishId: string = '';
    CountyId: string = '';
    VillageId: string = '';
    StreetId: string = '';
    HouseId: string = '';
    ApartmentId: string = '';
}

export class AddressApiResponse {
    CountyOptions: AddressLookupOptions;
    CityOptions: AddressLookupOptions;
    ParishOptions: AddressLookupOptions;
    VillageOptions: AddressLookupOptions;
    StreetOptions: AddressLookupOptions;
    HouseOptions: AddressLookupOptions;
    ApartmentOptions: AddressLookupOptions;
    PostCode: string;
    AddressText: string;
}


